export default [
  // {
  //   header: 'Dashboards',
  //   icon: 'HomeIcon',
  //   tag: '2',
  //   tagVariant: 'light-warning',
  //   children: [
  //     {
  //       title: 'eCommerce',
  //       route: 'dashboard-ecommerce',
  //       icon: 'ShoppingCartIcon',
  //     },
  //     {
  //       title: 'Analytics',
  //       route: 'dashboard-analytics',
  //       icon: 'ActivityIcon',
  //     },
  //   ],
  // },
  // {
  //   header: 'Apps & Pages',
  // },
  {
    title: 'Access Control',
    route: 'access-control',
    icon: 'ShieldIcon',
    // acl: {
    action: 'read',
    resource: 'ACL',
    // },
  },
]